<template>
  <div>
    <van-cell>
      <template slot="title">
        <van-button color="#57c4b7" size="small" round disabled>就诊建议</van-button>请选择下面推荐科室挂号就诊
      </template>
    </van-cell>
    <van-cell>
      <template slot="title">
        <van-button color="#57c4b7" size="small" round disabled>推荐科室</van-button>
      </template>
    </van-cell>
    <van-cell-group v-for="Ditem in list" :key="Ditem.index">
      <van-cell>
        <template slot="title">
          {{Ditem.depName}}
          <!-- <van-button color="#57c4b7" size="small" round disabled>推荐科室</van-button> -->
        </template>
        <van-button color="#57c4b7" size="small" plain round @click="orderReg(Ditem.depID)">预约挂号</van-button>
      </van-cell>
    </van-cell-group>
    <p style="font-size:12px; line-height:24px; color:#57c4b7">*温馨提示:以上推荐科室仅供参考</p>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;">
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      list: []
    };
  },
  created(){
    this.list = JSON.parse(sessionStorage.getItem("dep"));
    console.log(this.list);
  },
  methods: {
    orderReg(depid) {
      console.log(depid);
      store.commit("store/setDepid", depid);
      this.$router.replace({
          path: "/IDocList"
        }).catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
</style>